import {
  CultureInfoDeDE,
  CultureInfoEnGB,
  CultureInfoItIT,
  CultureInfoFrFR,
  CultureInfoPlPL,
  CultureInfoPtPT,
  CultureInfoEsES,
  CultureInfoNlNL,
} from '@trustedshops/tswp-core-common';

export const culturesAvailable = [
  // German
  CultureInfoDeDE,
  // CultureInfoDeAT, TODO: Investigate if this is needed https://trustedshops.atlassian.net/browse/CC-91
  // CultureInfoDeCH, TODO: Investigate if this is needed https://trustedshops.atlassian.net/browse/CC-91

  // English
  CultureInfoEnGB,

  // Italian
  CultureInfoItIT,
  // CultureInfoItCH,  TODO: Investigate if this is needed https://trustedshops.atlassian.net/browse/CC-91

  // French
  CultureInfoFrFR,
  // CultureInfoFrBE, TODO: Investigate if this is needed https://trustedshops.atlassian.net/browse/CC-91
  // CultureInfoFrCH, TODO: Investigate if this is needed https://trustedshops.atlassian.net/browse/CC-91

  // Polish
  CultureInfoPlPL,

  // Portuguese
  CultureInfoPtPT,

  // Spanish
  CultureInfoEsES,

  // Dutch
  CultureInfoNlNL,
  // CultureInfoNlBE  TODO: Investigate if this is needed https://trustedshops.atlassian.net/browse/CC-91
];
