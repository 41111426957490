import { Component, NgZone } from '@angular/core';
import userFlow from 'userflow.js';
import { environment } from '../../configuration/get-environment.util';
import { UserFlowService } from '../../services/user-flow.service';

@Component({
  selector: 'app-userflow-trigger',
  templateUrl: './userflow-trigger.component.html',
  styleUrls: ['./userflow-trigger.component.scss'],
})
export class UserflowTriggerComponent {
  private readonly checklistId: string = environment.userFlow.checklistId;

  public progress$ = this.userFlowService.progress$

  public constructor(
    private readonly userFlowService: UserFlowService,
    private readonly _ngZone: NgZone,
  ) { }

  ngAfterContentInit(): void {
    userFlow.on('flowEnded', this.flowEnded.bind(this));
    this.userFlowService.loadProgress();
  }

  public handleOpenOnboardingFlow(): void {
    if (!document.getElementsByClassName('userflowjs-bubble').length) {
      userFlow.start(this.checklistId);
    } else {
      userFlow.endAll();
    }
  }

  /**
   * Workaround to update the progress after the flow has ended
   * We need to run the change detection manually, because the event is not triggered inside the Angular zone
   * INFO: We have to wait a bit, because the event is triggered before the progress is updated
   */
  private flowEnded() {
    setTimeout(() => {
      this._ngZone.run(() =>
        this.userFlowService.loadProgress()
      )
    }, 1000)
  }

}
