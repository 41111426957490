<div class="topbar">
  <div class="topbar-breadcrumbs">
    <div *ngIf="breadcrumbs | async as $breadcrumbs">
      <ng-container *ngFor="let breadcrumb of $breadcrumbs; let i = index">
        <span class="topbar-breadcrumbs-separator" *ngIf="i > 0">‣</span>
        <user-interaction
          [interaction]="breadcrumb.userInteraction"
          class="topbar-breadcrumbs-interaction"
          *ngIf="i < $breadcrumbs.length - 1"
        >
          <span [innerText]="breadcrumb.label"></span>
        </user-interaction>
        <strong
          [innerText]="breadcrumb.label"
          *ngIf="i === $breadcrumbs.length - 1"
        ></strong>
      </ng-container>
    </div>
  </div>
  <nav class="topbar-meta_menu">

    <ng-container *ngIf="(isFreeAccount$ | async) && !(isUnverifiedAccount$ | async)">
      <ng-container *ngIf="(progress$ | async) as progress">
        <app-userflow-trigger *ngIf="progress < 100"></app-userflow-trigger>

        <ng-container *ngIf="progress > 99">
          <span class="plan-title" [translate]="'plan.basic.title'"></span>
          <app-upgrade-button></app-upgrade-button>
        </ng-container>
      </ng-container>
    </ng-container>

    <ul>
      <li
        *ngFor="let navigationItem of metaMenuItems | sort : 'order'"
        class="topbar-meta_menu-item"
      >
        <user-interaction
          [interaction]="navigationItem.userInteraction"
          [matTooltip]="navigationItem.label"
          matTooltipPosition="below"
          *ngIf="!navigationItem.children?.length"
          [matTooltipDisabled]="!userNotificationTooltipVisible"
          (click)="toggleNotificationTooltip()"
        >
          <div class="topbar-meta_menu-item-icon">
            <hls-icon
              [icon]="navigationItem.icon | removeHeliosIconPrefix"
            ></hls-icon>
            <navigation-badge
              *ngIf="!!navigationItem.badge"
              class="navigation-badge--floating navigation-badge--small"
              [badge]="navigationItem.badge"
            ></navigation-badge>
          </div>
        </user-interaction>
        <hls-menu
          align="right"
          *ngIf="navigationItem.children?.length"
          (click)="toggleUsersettingsTooltips()"
        >
          <hls-icon
            [icon]="navigationItem.icon"
            slot="toggle"
            [matTooltip]="navigationItem.label"
            matTooltipPosition="below"
            [matTooltipDisabled]="!userSettingsTooltipVisible"
          ></hls-icon>
          <div
            slot="entry"
            *ngFor="let childNavigationItem of navigationItem.children"
          >
            <user-interaction
              [interaction]="childNavigationItem.userInteraction"
            >
              <hls-icon [icon]="childNavigationItem.icon"></hls-icon>
              <span [innerText]="childNavigationItem.label"></span>
              <hls-icon
                *ngIf="childNavigationItem?.suffixIcon"
                class="suffix-icon"
                [icon]="childNavigationItem?.suffixIcon"
              ></hls-icon>
            </user-interaction>
          </div>
        </hls-menu>
      </li>
    </ul>
  </nav>
</div>
