import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NavigationRegions } from '@trustedshops/tswp-carrier-b2b-contracts';
import { AbstractPersistentEvent, Event, LocalStorageService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  NavigateUserInteractionHandler,
  NavigationItem,
  NavigationRegionService,
  NavigationService,
  OrganizationalContainerSelectorInfo,
  TOKENS,
} from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap, take } from 'rxjs/operators';
import { RxJsSubscriberComponent } from '../../core/rxjs-subscriber.component';
import { ActivePluginsService } from '../../services/active-plugins.service';
import { ActiveNavigationItemsStream, NavigationBarService } from '../../services/navigation-bar.service';
import { tertiaryNavigationRegion } from '../tertiary-menu-bar/tertiary-menu.navigation-region';
import { primaryNavigationConfiguration } from './primary-menu.navigation-region';
import { secondaryNavigationRegion } from './secondary-menu.navigation-region';
import { PostLoginService } from '../../services/post-login.service';

const LOCAL_STORAGE_COLLAPSED_KEY = 'application.navigation.isCollapsed';

@Component({
  selector: 'sidebar',
  styleUrls: ['./sidebar.component.scss'],
  templateUrl: './sidebar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent extends RxJsSubscriberComponent implements OnInit {
  //#region Properties
  public homeNavigationItem: NavigationItem = {
    label: of('Home'),
    icon: of('helios-icon helios-icon-feature-navigation-home'),
    userInteraction: of(new NavigateUserInteractionHandler('/')),
    children: [],
    order: 1000,
    $id: 'home'
  } as any;

  public get activeItems(): ActiveNavigationItemsStream {
    return this._navigationBarService.activeItems;
  }

  public get organizationalContainerSelector(): Observable<OrganizationalContainerSelectorInfo> {
    return this._navigationBarService.organizationalContainerSelector;
  }

  private _current$isNavigationCollapsed: boolean;
  private _isNavigationCollapsed: Observable<boolean>;
  public get isNavigationCollapsed(): Observable<boolean> {
    if (!this._isNavigationCollapsed) {
      this._isNavigationCollapsed = this._navigationRegionService
        .isRegionCollapsed(NavigationRegions.PrimaryMenu)
        .convertWith(RxJsSubjectBridge);
    }
    return this._isNavigationCollapsed;
  }

  private _sidebarPrimaryItems: BehaviorSubject<NavigationItem[]> = new BehaviorSubject([]);
  public get sidebarPrimaryItems(): Observable<NavigationItem[]> {
    return this._sidebarPrimaryItems;
  }

  private _sidebarSecondaryItems: BehaviorSubject<NavigationItem[]> = new BehaviorSubject([]);
  public get sidebarSecondaryItems(): Observable<NavigationItem[]> {
    return this._sidebarSecondaryItems;
  }

  private _sidebarLegalItems: NavigationItem[] = [];
  public get sidebarLegalItems(): NavigationItem[] {
    return this._sidebarLegalItems;
  }
  public set sidebarLegalItems(v: NavigationItem[]) {
    this._sidebarLegalItems = v;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(DOCUMENT)
    private readonly _document: Document,

    @Inject(TOKENS.NavigationService)
    private readonly _navigationService: NavigationService,

    @Inject(TOKENS.NavigationRegionService)
    private readonly _navigationRegionService: NavigationRegionService,

    @Inject(TOKENS_COMMON.LocalStorageService)
    private readonly _localStorageService: LocalStorageService,
    private readonly _activePluginsService: ActivePluginsService,
    private readonly _navigationBarService: NavigationBarService,
    private readonly _router: Router,
    private readonly _elementRef: ElementRef,
    private readonly _postLoginService: PostLoginService
  ) {
    super();
  }
  //#endregion

  //#region Public Methods
  public getTooltip(label: string): string {
    return this._current$isNavigationCollapsed || this.isOverflowing(label)
        ? label
        : null;
  }

  public isOverflowing(label: string): boolean {
    const el = this._document.createElement('span');
    el.innerText = label;
    el.className = 'sidebar-container-primary_menu-item__content__label--width-test';
    this._elementRef.nativeElement.appendChild(el);
    const isOverflowing = el.offsetWidth < el.scrollWidth;
    this._elementRef.nativeElement.removeChild(el);
    return isOverflowing;
  }

  public async ngOnInit(): Promise<void> {
    this._postLoginService.performPostLoginOperations();
    
    this._navigationService.registerNavigationItems([], NavigationRegions.PrimaryMenu);
    this._sidebarPrimaryItems.next(this._navigationService.getItemsForRegion(NavigationRegions.PrimaryMenu));
    this._sidebarLegalItems = this._navigationService.getItemsForRegion(NavigationRegions.LegalMenu);

    this._navigationRegionService.registerRegionConfig(secondaryNavigationRegion);
    const initiallyCollapsed = this.getInitialFoldingStatus();
    this._navigationRegionService.registerRegionConfig(primaryNavigationConfiguration(initiallyCollapsed));
    this._navigationRegionService.registerRegionConfig(tertiaryNavigationRegion);

    this.rememberSubscription(
      this.isNavigationCollapsed
        .subscribe(isCollapsed => {
          this._localStorageService.insertOrUpdate(LOCAL_STORAGE_COLLAPSED_KEY, isCollapsed);
          this._current$isNavigationCollapsed = isCollapsed;
        }),

      this._navigationBarService.activeItems
        .pipe(filter(items => !!items))
        .pipe(map(x => x.primary))
        .pipe(distinctUntilChanged())
        .subscribe(items => this.loadChildren(items)));

    this.listenToHomeRedirects();
  }

  public togglePrimaryMenu(): void {
    this._navigationRegionService.toggleRegion(NavigationRegions.PrimaryMenu);
  }
  //#endregion

  //#region Private Methods
  /**
   * @alias listenToHomeRedirects
   * @description Listen to home redirects and redirect to first available plugin when home is not available, fires only once
   */
  private listenToHomeRedirects(): void {
    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart &&
            (event?.url.includes(ActivePluginsService.HOME_PLUGIN_LABEL) || event.url === '' || event.url === '/' || event.url.includes('/?session_state'))
        ),
        first(),
        switchMap(() => this.redirectToFirstAvailablePlugin()),
      )
      .subscribe();
  }

  /**
   * @alias redirectToFirstAvailablePlugin
   * @description obtains first available plugin and redirects to it when home is unavailable
   * @returns Observable with redirect to first available plugin
   */
  private redirectToFirstAvailablePlugin() {
    return this._activePluginsService.activePlugins$.pipe(
      first(),
      switchMap((plugins) => {
        // get navigation for first active plugin
        const firstNavItem =
          this._activePluginsService.getNavigationForFirstActivePlugin(
            this._sidebarPrimaryItems.value,
            plugins
          );
          
        return firstNavItem.label === ActivePluginsService.HOME_PLUGIN_LABEL
          ? of(true)  // continue if home plugin is available
          : from(     // else redirect to first available navigation item
              this._router.navigate([
                (
                  firstNavItem?.userInteraction as NavigateUserInteractionHandler
                )?.uri,
              ])
            );
      })
    );
  }

  private getInitialFoldingStatus(): Event<boolean> {
    let wasCollapsed = this._localStorageService.select<boolean | null>(LOCAL_STORAGE_COLLAPSED_KEY);
    if (wasCollapsed === null) {
      wasCollapsed = true;
    }
    return new AbstractPersistentEvent(wasCollapsed);
  }

  private async loadChildren(currentPrimaryNavigationItems: NavigationItem[]): Promise<void> {
    if (!currentPrimaryNavigationItems) {
      return;
    }

    const children = currentPrimaryNavigationItems
      .map(x => x?.children || [])
      .reduce((prev, cur) => prev.concat(cur), []);

    this._sidebarSecondaryItems.next(children || []);

    if (Array.isArray(children) && children.length > 0) {
      this._navigationRegionService.collapseRegion(NavigationRegions.PrimaryMenu);
    }
  }
  //#endregion
}
