import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PluginsApiConfigurationModule } from './plugins-api-configuration.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TswpCarrierDefaultConfigModule } from '@trustedshops/tswp-carrier-core';
import { TrustedShopsWebPlatformCoreModule } from './tswp-core.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader, translateLoaderDeps } from './translation.config';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    TrustedShopsWebPlatformCoreModule,
    //#endregion

    //#region Trusted Shops Web Platform Apis
    PluginsApiConfigurationModule,
    //#endregion

    //#region @angular
    HttpClientModule,
    BrowserAnimationsModule,
    //#endregion

    //#region Material
    MatTooltipModule,
    //#endregion

    TranslateModule.forRoot({
      defaultLanguage: 'en-GB',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: translateLoaderDeps
      }
    })
  ],
  exports: [
    //#region Trusted Shops Web Platform
    TswpCarrierDefaultConfigModule,
    //#endregion

    //#region @angular
    HttpClientModule,
    BrowserAnimationsModule,
    //#endregion

    //#region Material
    MatTooltipModule,
    MatDialogModule,
    //#endregion

    TranslateModule
  ]
})
export class SharedModule {

}
