<div class="b2b-dialog">
  <p class="b2b-dialog-title" [translate]="data?.title"></p>
  <p class="b2b-dialog-subtitle" [translate]="data?.subtitle"></p>
  <button
    autofocus
    class="b2b-dialog-dismiss"
    type="button"
    (click)="dialogRef.close()"
  >
    <hls-icon class="b2b-dialog-dismiss-icon" icon="action-dismiss"></hls-icon>
  </button>
</div>
